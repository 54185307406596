import { Button, Label, Modal, TextInput } from "flowbite-react";
import React, { useState } from "react";
import { FAQ } from "../../../../interfaces/FAQ";
import { useNavigate } from "react-router";
import { number, object, string } from "yup";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase/firebaseConfig";
import HtmlEditor from "../../../../components/HtmlEditor/HtmlEditor";

const { v4: uuidv4 } = require("uuid");

interface AddFaqModal {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddFaqModal({ openModal, setOpenModal }: AddFaqModal) {
  const [question, setQuestion] = useState("");
  const [questionEn, setQuestionEn] = useState(""); // English question
  const [answerHTML, setAnswerHTML] = useState("");
  const [answerHTMLEn, setAnswerHTMLEn] = useState(""); // English answer
  const [order, setOrder] = useState(1);

  const [errorMessages, setErrorMessages] = useState<string[]>();

  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const navigate = useNavigate();

  let faqSchema = object({
    question: string().required("შეკითხვა აუცილებელია!"),
    questionEn: string().required("Question (English) is required!"),
    answerHTML: string().required("პასუხი აუცილებელია!"),
    answerHTMLEn: string().required("Answer (English) is required!"),
    order: number()
      .min(1, "რიგის ნომერი 1-ზე ნაკლები რიცხვი არ შეიძლება!")
      .required(),
  });

  const handleSave = async () => {
    try {
      await faqSchema.validate({
        question,
        questionEn,
        answerHTML,
        answerHTMLEn,
        order,
      });

      const updatedFaq: FAQ = {
        id: uuidv4(),
        question,
        questionEn,
        answerHTML,
        answerHTMLEn,
        order,
      };

      const faqDocRef = doc(db, "faq", updatedFaq.id);
      await setDoc(faqDocRef, updatedFaq, { merge: true });
      await updateDoc(faqDocRef, updatedFaq as { [x: string]: any });

      setOpenModal(false);
      navigate(0);
    } catch (e: any) {
      let messages = e.errors;
      setErrorMessages(messages);
    }
  };

  return (
    <>
      <Modal
        dismissible
        show={openModal}
        onClose={() => setOpenModal(false)}
        className="font-mtavvruli"
      >
        <Modal.Header className="text-main-blue font-bold">
          დაამატე <span className="text-main-red">{"ახალი შეკითხვა"}</span>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6 w-full">
            <div className="flex flex-col gap-4">
              {/* Question (Georgian) */}
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="ჩაწერეთ შეკითხვა (Georgian)" />
                </div>
                <TextInput
                  id="name"
                  type="text"
                  sizing="md"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
              </div>

              {/* Question (English) */}
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="nameEn" value="Enter Question (English)" />
                </div>
                <TextInput
                  id="nameEn"
                  type="text"
                  sizing="md"
                  value={questionEn}
                  onChange={(e) => setQuestionEn(e.target.value)}
                />
              </div>

              {/* Answer (Georgian) */}
              <HtmlEditor initialHtml={answerHTML} onChange={setAnswerHTML} />

              {/* Answer (English) */}
              <HtmlEditor
                initialHtml={answerHTMLEn}
                onChange={setAnswerHTMLEn}
              />

              {/* Order */}
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="base"
                    value="რიგის ნომერი (მერამდენე გამოჩნდეს)"
                  />
                </div>
                <TextInput
                  id="base"
                  type="text"
                  sizing="md"
                  value={order}
                  onChange={(e) => setOrder(Number(e.target.value))}
                />
              </div>
            </div>
          </div>
          {errorMessages &&
            errorMessages.map((message, index) => (
              <h3 key={index} className="text-main-red font-bold">
                {message}
              </h3>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="main-red"
            onClick={() => setOpenModal(false)}
            disabled={buttonsDisabled}
          >
            დახურვა
          </Button>
          <Button color="gray" onClick={handleSave} disabled={buttonsDisabled}>
            შენახვა
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddFaqModal;
