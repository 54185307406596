import React from "react";
import Container from "../Shared/Container/Container";
import { Button } from "flowbite-react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

interface BlogPreview {
  title: string;
  imageUrl: string;
  date: string;
  id: string;
}

function BlogPreview({ title, imageUrl, date, id }: BlogPreview) {

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="rounded-xl flex flex-col">
      <img
        src={imageUrl}
        alt="imageUrl"
        className="rounded-t-xl w-full h-56 object-cover"
      />
      <div className="border-[1px] border-t-0 border-main-blue rounded-b-xl flex-1 bg-main-background">
        <Container className="py-4 h-full flex flex-col justify-between">
          <h3 className="text-main-blue font-bold text-xl">{title}</h3>
          <h5 className="text-xs">{date}</h5>
          <Button color={"main-red"} className="bg-main-red mt-8" onClick={() => navigate(`/blog/${id}`)}>{t('seeBlog')}</Button>
        </Container>
      </div>
    </div>
  );
}

export default BlogPreview;
