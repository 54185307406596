import React, { useState } from "react";
import Container from "../Container/Container";
import { Button, Label, Textarea, TextInput } from "flowbite-react";
import { IoMdPerson } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
import { object, string } from "yup";
import { Mail } from "../../../interfaces/Mail";
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";
import { useTranslation } from "react-i18next";

const { v4: uuidv4 } = require("uuid");

function ContactUs() {
  const { t } = useTranslation();

  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const [errorMessages, setErrorMessages] = useState<string[]>();

  const [buttonText, setButtonText] = useState(t("send"));

  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  let mailSchema = object({
    name: string().required(t("nameRequired")),
    email: string()
      .email(t("emailInvalid"))
      .required(t("emailRequired")),
    phoneNumber: string().required(t("phoneNumberRequired")),
  });

  const handleSubmit = async () => {
    setButtonsDisabled(true);
    try {
      await mailSchema.validate({
        name,
        email,
        phoneNumber,
        message,
      });

      const newMail: Mail = {
        id: uuidv4(),
        name,
        email,
        message,
        phoneNumber,
        dateCreated: new Date().toISOString(),
      };

      const myCollection = collection(db, "mails");

      const myDocRef = doc(myCollection, newMail.id);

      await setDoc(myDocRef, newMail);
      setButtonsDisabled(false);

      setErrorMessages([]);
      setName("");
      setEmail("");
      setPhoneNumber("");
      setMessage("");

      setButtonText(t("theMessageHasBeenSent"));
      setTimeout(() => {
        setButtonText(t("send"));
      }, 5000);
    } catch (e: any) {
      console.error(e);
      let messages = e.errors;
      setErrorMessages(messages);
      setButtonsDisabled(false);

      setButtonText(t("errorOccured"));
      setTimeout(() => {
        setButtonText(t("send"));
      }, 5000);
    }
  };

  return (
    <>
      <div className="w-full py-12 border-y-[1px] border-solid border-gray-400" id="contact-us-section">
        <Container>
          <div className="border-main-red border-solid border-2 rounded-xl p-4 flex flex-col items-center">
            <div className="w-full lg:w-5/6 py-4">
              <h1 className="font-bold text-main-red text-2xl text-center lg:text-5xl">
                {t("contactUs")}
              </h1>
              <div className="flex flex-col gap-4 py-4 rounded-xl">
                <div className="flex flex-col gap-8">
                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div className="grid grid-cols-1 gap-4">
                      <div>
                        <div className="mb-2 block">
                          <Label
                            htmlFor="name"
                            value={t("name")}
                            className="text-main-red"
                          />
                        </div>
                        <TextInput
                          id="name"
                          type="text"
                          icon={IoMdPerson}
                          placeholder={t("namePlaceholder")}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <div className="mb-2 block">
                          <Label
                            htmlFor="email4"
                            value={t("email")}
                            className="text-main-red"
                          />
                        </div>
                        <TextInput
                          id="email4"
                          type="email"
                          icon={HiMail}
                          placeholder={t("emailPlaceholder")}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <div>
                        <div className="mb-2 block">
                          <Label
                            htmlFor="phone"
                            value={t("phoneNumber")}
                            className="text-main-red"
                          />
                        </div>
                        <TextInput
                          id="phone"
                          icon={FaPhoneAlt}
                          placeholder={t("phonePlaceholder")}
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="h-full flex flex-col">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="comment"
                          value={t("message")}
                          className="text-main-red"
                        />
                      </div>
                      <Textarea
                        id="comment"
                        placeholder={t("messagePlaceholder")}
                        required
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="h-52 lg:flex-1"
                      />
                    </div>
                  </div>
                  <div className="w-full">
                    <Button
                      className="w-full"
                      color={"main-red"}
                      onClick={handleSubmit}
                      disabled={buttonsDisabled}
                    >
                      {buttonText}
                    </Button>
                  </div>
                </div>
              </div>
              {errorMessages &&
                errorMessages.map((message) => (
                  <h3 key={message} className="font-bold text-main-red text-md">
                    {message}
                  </h3>
                ))}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default ContactUs;
