import React, { useEffect, useState } from "react";
import HeaderSpace from "../../../components/Shared/HeaderSpace/HeaderSpace";
import Sidebar from "../../../components/Shared/Sidebar/Sidebar";
import { Button, Table } from "flowbite-react";
import { Certificate } from "../../../interfaces/Certificate";
import {
  collection,
  DocumentData,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";
import AdminCertificatesTableRow from "./Table/AdminCertificatesTableRow";
import AddCertificateModal from "./Modals/AddCertificateModal";

function AdminCertificates() {
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "certificates"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Certificate)
      );
      setCertificates(items);
    };

    fetchData();
  }, []);

  return (
    <>
      <HeaderSpace className="bg-white h-20 lg:h-36" />
      <div className="flex flex-col lg:flex-row">
        <Sidebar />
        <div className="flex-1 py-12">
          <div className="w-10/12 mx-auto flex flex-col gap-8">
            <div className="flex flex-col lg:flex-row justify-between gap-4">
              <h2 className="text-main-red font-bold text-3xl text-center lg:text-left lg:text-5xl">
                სერტიფიკატები
              </h2>
              <Button
                className="flex items-center gap-2 bg-main-red"
                color={"main-red"}
                onClick={() => setOpenModal(!openModal)}
              >
                დამატება{" +" }
              </Button>
            </div>
            <div className="overflow-x-auto">
              <Table striped>
                <Table.Head>
                  <Table.HeadCell>სურათი</Table.HeadCell>
                  <Table.HeadCell>სერტიფიკატის სახელი</Table.HeadCell>
                  <Table.HeadCell>
                    <span className="sr-only">Edit</span>
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {certificates &&
                    certificates.map((certificate) => (
                      <AdminCertificatesTableRow
                        key={certificate.id}
                        certificate={certificate}
                      />
                    ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <AddCertificateModal openModal={openModal} setOpenModal={setOpenModal} />
    </>
  );
}

export default AdminCertificates;
