import {
  Button,
  FileInput,
  Label,
  Modal,
  Select,
  TextInput,
} from "flowbite-react";
import React, { useState } from "react";
import { University } from "../../../../interfaces/University";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import {
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase/firebaseConfig";
import { object, string } from "yup";
import { useNavigate } from "react-router";
import HtmlEditor from "../../../../components/HtmlEditor/HtmlEditor";
import { REGIONS } from "../../../../enums/regions";

const { v4: uuidv4 } = require("uuid");

interface EditUniversityModalProps {
  university: University;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function EditUniversityModal({
  university,
  openModal,
  setOpenModal,
}: EditUniversityModalProps) {
  const [name, setName] = useState(university.name);
  const [nameEn, setNameEn] = useState(university.nameEn); // English name
  const [slug, setSlug] = useState(university.slug);
  const [region, setRegion] = useState(university.region);
  const [body, setBody] = useState(university.bodyHTML);
  const [bodyEn, setBodyEn] = useState(university.bodyHTMLEn); // English description

  const [errorMessages, setErrorMessages] = useState<string[] | null>(null);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const navigate = useNavigate();

  let universitySchema = object({
    name: string().required("სახელი აუცილებელია!"),
    nameEn: string().required("Name in English is required!"),
    slug: string()
      .required("სლაგი აუცილებელია!")
      .length(2, "სლაგი უნდა იყოს 2 სიმბოლოიანი. (მაგ: GB, US, FR)"),
    region: string().required("რეგიონი აუცილებელია!"),
    body: string().required("ტანი აუცილებელია"),
    bodyEn: string().required("Body in English is required!"),
  });

  const handleSave = async () => {
    if (!university) {
      setErrorMessages(["No university selected for editing."]);
      return;
    }

    try {
      await universitySchema.validate({
        name,
        nameEn,
        slug,
        body,
        bodyEn,
        region,
      });

      const updatedUniversity: University = {
        id: university.id,
        name,
        nameEn,
        bodyHTML: body,
        bodyHTMLEn: bodyEn,
        region,
        slug,
      };

      const universityDocRef = doc(db, "universities", university.id);
      await setDoc(universityDocRef, updatedUniversity, { merge: true });
      await updateDoc(universityDocRef, updatedUniversity as { [x: string]: any });

      setOpenModal(false);
      navigate(0);
    } catch (e: any) {
      let messages = e.errors;
      setErrorMessages(messages);
    }
  };

  const handleDelete = async (universityId: string) => {
    setButtonsDisabled(true);
    try {
      const universityDocRef = doc(db, "universities", universityId);
      await deleteDoc(universityDocRef);
      navigate(0); // Refresh the page or update the state to reflect changes
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
    setButtonsDisabled(false);
  };

  return (
    <>
      <Modal
        dismissible
        show={openModal}
        onClose={() => setOpenModal(false)}
        className="font-mtavvruli"
      >
        <Modal.Header className="text-main-blue font-bold">
          უნივერსიტეტის რედაქტირება
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6 w-full">
            <div className="flex flex-col gap-4">
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="სახელი" />
                </div>
                <TextInput
                  id="name"
                  type="text"
                  sizing="md"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="nameEn" value="სახელი (ინგლისურად)" />
                </div>
                <TextInput
                  id="nameEn"
                  type="text"
                  sizing="md"
                  value={nameEn}
                  onChange={(e) => setNameEn(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="slug" value="სლაგი" />
                </div>
                <TextInput
                  id="slug"
                  type="text"
                  sizing="md"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="countries" value="აირჩიე რეგიონი" />
                </div>
                <Select
                  id="countries"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  required
                >
                  <option>{REGIONS.Europe}</option>
                  <option>{REGIONS.Asia}</option>
                  <option>{REGIONS.NorthAmerica}</option>
                  <option>{REGIONS.SouthAmerica}</option>
                  <option>{REGIONS.Oceania}</option>
                </Select>
              </div>
              <HtmlEditor onChange={setBody} initialHtml={body} />
              <HtmlEditor
                onChange={setBodyEn}
                initialHtml={bodyEn}
              />
            </div>
          </div>
          {errorMessages &&
            errorMessages.map((message, index) => (
              <h3 key={index} className="text-main-red font-bold">
                {message}
              </h3>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button color="main-red" onClick={() => setOpenModal(false)}>
            დახურვა
          </Button>
          <Button color="gray" onClick={handleSave}>
            შენახვა
          </Button>
          <Button
            color={"warning"}
            onClick={() => handleDelete(university.id)}
            disabled={buttonsDisabled}
          >
            წაშლა
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditUniversityModal;
