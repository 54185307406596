import { Certificate } from "crypto";
import React, { useEffect, useRef, useState } from "react";

interface FullscreenSlidingCarouselProps {
  children: React.ReactNode;
}

const FullscreenSlidingCarousel: React.FC<FullscreenSlidingCarouselProps> = ({ children }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const animationFrameId = useRef<number | null>(null);

  useEffect(() => {
    let startPosition = 0;

    const scroll = () => {
      if (containerRef.current) {
        startPosition -= 1; // Adjust this value to change the scroll speed
        if (startPosition <= -containerRef.current.scrollWidth / 2) {
          startPosition = 0; // Reset scroll to start for seamless effect
        }
        containerRef.current.style.transform = `translateX(${startPosition}px)`;
      }
      animationFrameId.current = requestAnimationFrame(scroll);
    };

    scroll();

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
      }
    };
  }, []);

  return (
    <div className="calc(100vw - 8px) h-96 overflow-hidden flex items-center justify-center relative">
      <div
        ref={containerRef}
        className="flex absolute whitespace-nowrap"
        style={{ width: "200%" }} // Double width for looping effect
      >
        {/* Main Content */}
        <div className="flex">
          {React.Children.map(children, (child, index) => (
            <div
              key={`carousel-item-${index}`}
              className="w-64 min-h-64 px-2 flex flex-col items-center justify-start"
            >
              {child}
            </div>
          ))}
        </div>

        {/* Duplicate Content for Seamless Scrolling */}
        <div className="flex">
          {React.Children.map(children, (child, index) => (
            <div
              key={`carousel-item-duplicate-${index}`}
              className="w-64 min-h-64 px-2 flex flex-col items-center justify-start"
            >
              {child}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FullscreenSlidingCarousel;
