import { Button, FileInput, Label, Modal, TextInput } from "flowbite-react";
import React, { useState } from "react";
import { Employee } from "../../../../interfaces/Employee";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../../../firebase/firebaseConfig";
import { number, object, string } from "yup";
import { useNavigate } from "react-router";

const { v4: uuidv4 } = require("uuid");

interface AddTeamModalProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddTeamModal({ openModal, setOpenModal }: AddTeamModalProps) {
  const [name, setName] = useState("");
  const [nameEn, setNameEn] = useState(""); // English name
  const [rolesStr, setRolesStr] = useState("");
  const [rolesStrEn, setRolesStrEn] = useState(""); // English roles
  const [order, setOrder] = useState(1);
  const [file, setFile] = useState<File | null>(null);
  const [errorMessages, setErrorMessages] = useState<string[] | null>(null);
  const navigate = useNavigate();

  let teamMemberSchema = object({
    name: string().required("სახელი აუცილებელია!"),
    nameEn: string().required("English name is required!"),
    rolesStr: string().required("როლები აუცილებელია!"),
    rolesStrEn: string().required("Roles in English are required!"),
    imageUrl: string().required("სურათი აუცილებელია!"),
    order: number().min(1, "1-ზე ნაკლები რიცხვი არ შეიძლება!").required(),
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleSave = async () => {
    try {
      let imageUrl = "";

      if (file) {
        const storage = getStorage();
        const storageRef = ref(storage, `images/${file.name}`);
        await uploadBytes(storageRef, file);
        imageUrl = await getDownloadURL(storageRef);
      }

      await teamMemberSchema.validate({
        name,
        nameEn,
        rolesStr,
        rolesStrEn,
        imageUrl,
        order,
      });

      const newEmployee: Employee = {
        id: uuidv4(),
        name,
        nameEn,
        imageUrl,
        roles: rolesStr.split(",").map((role) => role.trim()),
        rolesEn: rolesStrEn.split(",").map((role) => role.trim()), // English roles
        order,
      };

      const myCollection = collection(db, "employees");
      const myDocRef = doc(myCollection, newEmployee.id);

      // Add or update the document
      await setDoc(myDocRef, newEmployee);

      setOpenModal(false);
      navigate(0);
    } catch (e: any) {
      let messages = e.errors;
      setErrorMessages(messages);
    }
  };

  return (
    <>
      <Modal
        dismissible
        show={openModal}
        onClose={() => setOpenModal(false)}
        className="font-mtavvruli"
      >
        <Modal.Header className="text-main-blue font-bold">
          ახალი გუნდის წევრის დამატება
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6 w-full">
            <div className="flex flex-col gap-4">
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="სახელი" />
                </div>
                <TextInput
                  id="name"
                  type="text"
                  sizing="md"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="nameEn" value="სახელი (ინგლისურად)" />
                </div>
                <TextInput
                  id="nameEn"
                  type="text"
                  sizing="md"
                  value={nameEn}
                  onChange={(e) => setNameEn(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="roles" value="როლები (გამოყავით მძიმით)" />
                </div>
                <TextInput
                  id="roles"
                  type="text"
                  sizing="md"
                  value={rolesStr}
                  onChange={(e) => setRolesStr(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="rolesEn"
                    value="როლები (ინგლისურად) გამოყავით მძიმით"
                  />
                </div>
                <TextInput
                  id="rolesEn"
                  type="text"
                  sizing="md"
                  value={rolesStrEn}
                  onChange={(e) => setRolesStrEn(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label
                    htmlFor="base"
                    value="რიგის ნომერი (მერამდენე გამოჩნდეს)"
                  />
                </div>
                <TextInput
                  id="base"
                  type="text"
                  sizing="md"
                  value={order}
                  onChange={(e) => setOrder(Number(e.target.value))}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="file" value="ატვირთე სურათი" />
                </div>
                <FileInput
                  id="file"
                  helperText="აირჩიე სურათი"
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
          {errorMessages &&
            errorMessages.map((message, index) => (
              <h3 key={index} className="text-main-red font-bold">
                {message}
              </h3>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button color="main-red" onClick={() => setOpenModal(false)}>
            დახურვა
          </Button>
          <Button color="gray" onClick={handleSave}>
            შენახვა
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddTeamModal;
