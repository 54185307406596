import { Table } from 'flowbite-react';
import React, { useState } from 'react'
import { AboutPhoto } from '../../../../interfaces/AboutPhoto';
import EditAboutPhotoModal from '../Modals/EditAboutPhotoModal';

function AdminAboutPhotosTableRow(aboutPhoto: AboutPhoto) {
    const [openModal, setOpenModal] = useState(false);
    return (
      <>
        <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
          <Table.Cell>
            <img src={aboutPhoto.imageUrl} alt="empl" className="w-16 max-h-16" />
          </Table.Cell>
          <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
            {aboutPhoto.order}
          </Table.Cell>
          <Table.Cell>
            <a
              onClick={() => setOpenModal(true)}
              className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 hover:cursor-pointer"
            >
              შევცლა
            </a>
          </Table.Cell>
        </Table.Row>
        <EditAboutPhotoModal
          aboutPhoto={aboutPhoto}
          openModal={openModal}
          setOpenModal={setOpenModal}
        />
      </>
    );
}

export default AdminAboutPhotosTableRow