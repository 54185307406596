import React from "react";

import { Spinner as Sp } from "flowbite-react";
import Container from "../Container/Container";

function Spinner() {
  return (
    <div className="absolute left-0 top-0 w-screen h-screen z-[1000] bg-white text-white flex flex-col justify-center items-center">
      <Container className="flex justify-center">
        <div className="flex flex-wrap gap-2 py-24">
          <Sp
            aria-label="Left-aligned spinner example"
            className="h-48 w-48 text-main-red"
          />
        </div>
      </Container>
    </div>
  );
}

export default Spinner;
