import { useTranslation } from "react-i18next";
import Container from "../../Shared/Container/Container";
import { doc, DocumentData, getDoc } from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";
import { useEffect, useState } from "react";
import { Spinner } from "flowbite-react";

function MainStats() {
  const { t, i18n } = useTranslation(); // Initialize translation hook
  const [aboutData, setAboutData] = useState<DocumentData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const aboutDocRef = doc(db, "pages", "about");
      const aboutDocSnapshot = await getDoc(aboutDocRef);

      if (aboutDocSnapshot.exists()) {
        setAboutData(aboutDocSnapshot.data());
      } else {
        console.log("No such document in pages/about!");
      }
    };

    fetchData();
  }, []);

  if (!aboutData) {
    return <Spinner />;
  }

  // Conditionally render stats based on the selected language
  const experience =
    i18n.language === "en"
      ? aboutData.stats.experienceEn
      : aboutData.stats.experience;
  const partners =
    i18n.language === "en"
      ? aboutData.stats.partnersEn
      : aboutData.stats.partners;
  const students =
    i18n.language === "en"
      ? aboutData.stats.studentsEn
      : aboutData.stats.students;

  return (
    <div className="py-8 lg:py-16 border-y-2 border-y-gray-300">
      <Container>
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="flex flex-col text-center gap-2">
            <h4 className="text-sm lg:text-2xl text-main-blue">{t("students")}</h4>
            <h4 className="text-3xl font-bold lg:text-5xl text-center text-main-red">
              {students}
            </h4>
          </div>
          <div className="flex flex-col text-center gap-2">
            <h4 className="text-sm lg:text-2xl text-main-blue">{t("partners")}</h4>
            <h4 className="text-3xl font-bold lg:text-5xl text-center text-main-red">
              {partners}
            </h4>
          </div>
          <div className="flex flex-col text-center gap-2">
            <h4 className="text-sm lg:text-2xl text-main-blue">{t("countries")}</h4>
            <h4 className="text-3xl font-bold lg:text-5xl text-center text-main-red">
              {50}
            </h4>
          </div>
          <div className="flex flex-col text-center gap-2">
            <h4 className="text-sm lg:text-2xl text-main-blue">{t("experience")}</h4>
            <h4 className="text-3xl font-bold lg:text-5xl text-center text-main-red">
              {experience}
            </h4>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default MainStats;
