import React from "react";
import Container from "../../Shared/Container/Container";
import { Button } from "flowbite-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next"; // Import useTranslation for translations

function MainPartners() {
  const { t } = useTranslation(); // Initialize translation hook

  return (
    <div
      className="relative bg-repeat" // Apply background and animation class
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px", // Initial size of the dots
      }}
    >
      {/* Container for content */}
      <Container>
        <div className="flex flex-col gap-8 lg:gap-16 py-16 lg:flex-row lg:justify-center items-center">
          <img
            src="/images/logos/ell-logo-way-big.png"
            alt="universities-hero"
            className="h-64 lg:h-[420px] rounded-lg px-16"
          />
          <div className="flex flex-col gap-4">
            <div>
              <h3 className="font-bold text-left text-2xl text-main-red">{t('EllShetavazebebiSazgvargaret')}</h3>
              <ul className="list-disc translate-x-6">
                <li className="text-lg text-main-blue">
                  {t('SchoolPrograms')}
                </li>
                <li className="text-lg text-main-blue">
                  {t('PreuniversityPrograms')}
                </li>
                <li className="text-lg text-main-blue">
                  {t('UniversityPrograms')}
                </li>
                <li className="text-lg text-main-blue">
                  {t('ProffesionalPrograms')}
                </li>
                <li className="text-lg text-main-blue">
                  {t('ZogadiAndProf')}
                </li>
                <li className="text-lg text-main-blue">
                  {t('ZogadiEnglish')}
                </li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold text-left text-2xl text-main-red">{t('EllShetavazebebiSaqartveloshi')}</h3>
              <ul className="list-disc translate-x-6">
                <li className="text-lg text-main-blue">
                  {t('ZogadiEnglishKurs')}
                </li>
                <li className="text-lg text-main-blue">
                  {t('Kembriji')}
                </li>
                <li className="text-lg text-main-blue">
                  {t('IeltsToefl')}
                </li>
                <li className="text-lg text-main-blue">
                  {t('SatGmat')}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default MainPartners;
