import React, { useState } from "react";
import Container from "../../components/Shared/Container/Container";
import { IoMdPerson } from "react-icons/io";
import { FaPhoneAlt, FaFacebook, FaInstagram } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
import { Button, Label, Textarea, TextInput } from "flowbite-react";
import GoogleMapsComponent from "../../components/GoogleMapsComponent/GoogleMapsComponent";
import { object, string } from "yup";
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import { Mail } from "../../interfaces/Mail";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";

const { v4: uuidv4 } = require("uuid");

function Contact() {
  const { t } = useTranslation();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [buttonText, setButtonText] = useState(t("send"));
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  let mailSchema = object({
    name: string().required(t("name") + " " + t("isRequired")),
    email: string()
      .email(t("email") + " " + t("mustBeValid"))
      .required(t("email") + " " + t("isRequired")),
    phoneNumber: string().required(t("phone") + " " + t("isRequired")),
  });

  const handleSubmit = async () => {
    setButtonsDisabled(true);
    try {
      await mailSchema.validate({
        name,
        email,
        phoneNumber,
        message,
      });

      const newMail: Mail = {
        id: uuidv4(),
        name,
        email,
        message,
        phoneNumber,
        dateCreated: new Date().toISOString(),
      };

      const myCollection = collection(db, "mails");
      const myDocRef = doc(myCollection, newMail.id);

      await setDoc(myDocRef, newMail);
      setButtonsDisabled(false);

      setErrorMessages([]);
      setName("");
      setEmail("");
      setPhoneNumber("");
      setMessage("");

      setButtonText(t("messageSent"));
      setTimeout(() => {
        setButtonText(t("send"));
      }, 5000);
    } catch (e: any) {
      console.error(e);
      let messages = e.errors;
      setErrorMessages(messages);
      setButtonsDisabled(false);

      setButtonText(t("errorOccured"));
      setTimeout(() => {
        setButtonText(t("send"));
      }, 5000);
    }
  };

  return (
    <div
      className="relative bg-repeat"
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px",
      }}
    >
      <div className="flex flex-col">
        <div className="relative">
          <img
            src="/images/backgrounds/background-03.jpg"
            alt="background-06.jpg"
            className="w-screen h-[50vh] lg:h-[vh] brightness-[95%] object-cover"
          />
          <div className="w-full absolute bottom-4">
            <Container>
              <h1 className="text-4xl font-bold text-main-red">
                {t("contactUs")}
              </h1>
            </Container>
          </div>
        </div>
      </div>
      <div className="py-12">
        <Container>
          <div className="flex flex-col gap-16">
            <div className="flex flex-col gap-8">
              <div className="flex flex-col gap-8">
                <div className="grid grid-cols-1 lg:grid-cols-1 gap-4 lg:gap-16">
                  <div className="grid grid-cols-1 lg:col-span-2 gap-4">
                    <div>
                      <div className="mb-2 block">
                        <Label
                          htmlFor="name"
                          value={t("name")}
                          className="text-main-red"
                        />
                      </div>
                      <TextInput
                        id="name"
                        type="text"
                        icon={IoMdPerson}
                        placeholder={t("name") + "..."}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label
                          htmlFor="email4"
                          value={t("email")}
                          className="text-main-red"
                        />
                      </div>
                      <TextInput
                        id="email4"
                        type="email"
                        icon={HiMail}
                        placeholder={t("email") + "..."}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                    <div>
                      <div className="mb-2 block">
                        <Label
                          htmlFor="phone"
                          value={t("phone")}
                          className="text-main-red"
                        />
                      </div>
                      <TextInput
                        id="phone"
                        icon={FaPhoneAlt}
                        placeholder={t("phone") + "..."}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                      />
                    </div>
                    <div className="h-full flex flex-col">
                      <div className="mb-2 block">
                        <Label
                          htmlFor="comment"
                          value={t("message")}
                          className="text-main-red"
                        />
                      </div>
                      <Textarea
                        id="comment"
                        placeholder={t("message") + "..."}
                        required
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="h-52 lg:flex-1"
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <Button
                    className="w-full bg-main-red"
                    color={"main-red"}
                    onClick={handleSubmit}
                    disabled={buttonsDisabled}
                  >
                    {buttonText}
                  </Button>
                  {errorMessages &&
                    errorMessages.map((message) => (
                      <h3
                        key={message}
                        className="font-bold text-main-red text-md"
                      >
                        {message}
                      </h3>
                    ))}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <div className="flex flex-col gap-4">
                <h2 className="text-main-red font-bold text-center lg:text-left text-2xl">
                  {t("details")}
                </h2>
                <div className="flex flex-col gap-1">
                  <Link
                    to={"https://maps.app.goo.gl/kGqN2VbasqMVWfm39"}
                    target="_blank"
                    className="group"
                  >
                    <h2 className="text-gray-500 text-left text-sm">
                      <strong>{t("address")}:</strong>{" "}
                      <span className="group-hover:text-red-500">
                        {i18n.language === 'en' ? ' 30A Vazha Pshavela Avenue, 3rd Floor, Office 12, Tbilisi 0177, Georgia' :
                         'ვაჟა ფშაველას გამზაირი, 30ა, მესამე სართული, ოფისი 0177, თბილისი'}
                      </span>
                    </h2>
                  </Link>
                  <h2 className="text-gray-500 text-left text-sm">
                    <strong>{t("phoneLabel")}:</strong>{" "}
                    <Link
                      to={"tel:+995322399246"}
                      target="_blank"
                      className="group hover:text-red-500"
                    >
                      <span>{"+995 322 399246"}</span>
                    </Link>
                    {" | "}
                    <Link
                      to={"tel:+995322391063"}
                      target="_blank"
                      className="group hover:text-red-500"
                    >
                      <span>{"+995 322 391063"}</span>
                    </Link>
                  </h2>
                  <h2 className="text-gray-500 text-left text-sm">
                    <strong>{t("mobilePhoneLabel")}:</strong>{" "}
                    <Link
                      to={"tel:+995599504012"}
                      target="_blank"
                      className="group hover:text-red-500"
                    >
                      <span>{"+995 599 504012"}</span>
                    </Link>
                    {" | "}
                    <Link
                      to={"tel:+995599248851"}
                      target="_blank"
                      className="group hover:text-red-500"
                    >
                      <span>{"+995 599 248851"}</span>
                    </Link>
                  </h2>
                  <h2 className="text-gray-500 text-left text-sm">
                    <strong>{t("emailLabel")}:</strong>{" "}
                    <Link
                      to={"mailto:info@ell.ge"}
                      target="_blank"
                      className="group hover:text-red-500"
                    >
                      <span>{"info@ell.ge"}</span>
                    </Link>
                    {" | "}
                    <Link
                      to={"mailto:nmakatsaria@ell.ge"}
                      target="_blank"
                      className="group hover:text-red-500"
                    >
                      <span>{"nmakatsaria@ell.ge"}</span>
                    </Link>
                  </h2>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <h2 className="text-main-red font-bold text-center lg:text-left text-2xl">
                  {t("socialMedia")}
                </h2>
                <div className="flex gap-2">
                  <Link
                    to={"https://www.facebook.com/ELLstudyabroad"}
                    target="_blank"
                  >
                    <div className="flex items-center text-white rounded-full p-4 bg-main-red">
                      <FaFacebook />
                    </div>
                  </Link>
                  <Link
                    to={"https://www.instagram.com/ellstudy/"}
                    target="_blank"
                  >
                    <div className="flex items-center text-white rounded-full p-4 bg-main-red">
                      <FaInstagram />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <GoogleMapsComponent />
    </div>
  );
}

export default Contact;
