import React, { useState } from "react";
import { Table } from "flowbite-react";
import EditPartnerModal from "../Modals/EditPartnerModal";

interface Partner {
  id: string;
  imageUrl: string;
}

interface AdminPartnerTableRowProps extends Partner {}

const AdminPartnerTableRow: React.FC<AdminPartnerTableRowProps> = ({ id, imageUrl }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {/* Table row displaying partner details */}
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
        {/* <Table.Cell>{id}</Table.Cell> */}
        <Table.Cell>
          <img
            src={imageUrl}
            alt={`Partner ${id}`}
            className="h-12 w-24 object-cover"
          />
        </Table.Cell>
        <Table.Cell>
          <a
            onClick={() => setOpenModal(true)}
            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 hover:cursor-pointer"
          >
            შეცვლა
          </a>
        </Table.Cell>
      </Table.Row>

      {/* Modal for viewing partner details */}
      <EditPartnerModal
        partner={{ id, imageUrl }} // Pass the partner details to the modal
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default AdminPartnerTableRow;
