import { Button, Table } from "flowbite-react";
import { useEffect, useState } from "react";
import Sidebar from "../../../components/Shared/Sidebar/Sidebar";
import HeaderSpace from "../../../components/Shared/HeaderSpace/HeaderSpace";
import { FAQ } from "../../../interfaces/FAQ";
import {
  collection,
  DocumentData,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";
import AdminFaqTableRow from "./Table/AdminFaqTableRow";
import AddFaqModal from "./Modal/AddFaqModal";

function AdminFaq() {
  const [openModal, setOpenModal] = useState(false);
  const [FAQs, setFAQs] = useState<FAQ[]>();

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "faq"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as FAQ)
      );
      setFAQs(items);
    };

    fetchData();
  }, []);

  return (
    <>
      <HeaderSpace className="bg-white h-20 lg:h-36" />
      <div className="flex flex-col lg:flex-row">
        <Sidebar />
        <div className="flex-1 py-12">
          <div className="w-10/12 mx-auto flex flex-col gap-8">
          <div className="flex flex-col lg:flex-row justify-between gap-4">
          <h2 className="text-main-red font-bold text-xl text-center lg:text-left lg:text-4xl">
                ხშირად დასმული შეკითხვები
              </h2>
              <Button
                color={"main-red"}
                className="bg-main-red flex items-center"
                onClick={() => setOpenModal(!openModal)}
              >
                დამატება +
              </Button>
            </div>
            <div className="overflow-x-auto">
              <Table striped>
                <Table.Head>
                  <Table.HeadCell>შეკითხვა</Table.HeadCell>
                  <Table.HeadCell>პასუხი</Table.HeadCell>
                  <Table.HeadCell>რიგი</Table.HeadCell>
                  <Table.HeadCell>
                    <span className="sr-only">Edit</span>
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {FAQs &&
                      FAQs.map((faq, index) => (
                        <>
                          <AdminFaqTableRow
                            id={faq.id}
                            question={faq.question}
                            answerHTML={faq.answerHTML}
                            order={faq.order}
                            key={faq.id}
                            answerHTMLEn={faq.answerHTMLEn}
                            questionEn={faq.questionEn}
                          />
                        </>
                      ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <AddFaqModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        />
    </>
  );
}

export default AdminFaq;
