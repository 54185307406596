import React, { useState } from "react";
import { Student } from "../../../../interfaces/Student";
import { Button, FileInput, Label, Modal, TextInput } from "flowbite-react";
import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase/firebaseConfig";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { object, string } from "yup";
import { useNavigate } from "react-router";
import HtmlEditor from "../../../../components/HtmlEditor/HtmlEditor";

interface EditStudentModal {
  student: Student;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function EditStudentModal({
  student,
  openModal,
  setOpenModal,
}: EditStudentModal) {
  const [name, setName] = useState(student.name);
  const [nameEn, setNameEn] = useState(student.nameEn); // English name
  const [jobsStr, setJobsStr] = useState(student.jobs.join(", "));
  const [descriptionHTML, setDescriptionHTML] = useState(
    student.descriptionHTML
  );
  const [descriptionHTMLEn, setDescriptionHTMLEn] = useState(
    student.descriptionHTMLEn
  ); // English description

  const [file, setFile] = useState<File | null>(null);
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const navigate = useNavigate();

  let studentSchema = object({
    name: string().required("სახელი აუცილებელია!"),
    nameEn: string().required("English name is required!"),
    jobsStr: string().required("სამსახურები ან უნივერსიტეტები აუცილებელია!"),
    imageUrl: string().required("სურათი აუცილებელია!"),
    descriptionHTML: string().required("აღწერა აუცილებელია!"),
    descriptionHTMLEn: string().required("English description is required!"),
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleSave = async () => {
    if (!student) {
      setErrorMessages(["No student selected for editing."]);
      return;
    }

    try {
      let imageUrl = student.imageUrl;

      if (file) {
        const storage = getStorage();
        const storageRef = ref(storage, `images/${file.name}`);
        await uploadBytes(storageRef, file);
        imageUrl = await getDownloadURL(storageRef);
      }

      await studentSchema.validate({
        name: name,
        nameEn: nameEn,
        jobsStr,
        imageUrl: imageUrl,
        descriptionHTML: descriptionHTML,
        descriptionHTMLEn: descriptionHTMLEn,
      });

      const updatedStudent: Student = {
        id: student.id,
        name,
        nameEn,
        imageUrl,
        jobs: jobsStr.split(",").map((job) => job.trim()),
        descriptionHTML: descriptionHTML,
        descriptionHTMLEn: descriptionHTMLEn,
      };

      const studentDocRef = doc(db, "students", student.id);
      await setDoc(studentDocRef, updatedStudent, { merge: true });
      await updateDoc(studentDocRef, updatedStudent as { [x: string]: any });

      setOpenModal(false);
      navigate(0);
    } catch (e: any) {
      console.error(e);
      let messages = e.errors;
      setErrorMessages(messages);
    }
  };

  const handleDelete = async (studentId: string) => {
    setButtonsDisabled(true);
    try {
      const studentDocRef = doc(db, "students", studentId);
      await deleteDoc(studentDocRef);
      navigate(0); // Refresh the page or update the state to reflect changes
    } catch (error) {
      console.error("Error deleting document: ", error);
    }
    setButtonsDisabled(false);
  };

  return (
    <>
      <Modal
        dismissible
        show={openModal}
        onClose={() => setOpenModal(false)}
        className="font-mtavvruli"
      >
        <Modal.Header className="text-main-blue font-bold">
          შეცვალე <span className="text-main-red">{student.name}</span>
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6 w-full">
            <div className="flex flex-col gap-4">
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="სახელი" />
                </div>
                <TextInput
                  id="name"
                  type="text"
                  sizing="md"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="nameEn" value="სახელი (ინგლისურად)" />
                </div>
                <TextInput
                  id="nameEn"
                  type="text"
                  sizing="md"
                  value={nameEn}
                  onChange={(e) => setNameEn(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="base" value="სამსახურები და უნივერსიტეტები" />
                </div>
                <TextInput
                  id="base"
                  type="text"
                  sizing="md"
                  value={jobsStr}
                  onChange={(e) => setJobsStr(e.target.value)}
                />
              </div>
              <HtmlEditor
                onChange={setDescriptionHTML}
                initialHtml={descriptionHTML}
              />
              <HtmlEditor
                onChange={setDescriptionHTMLEn}
                initialHtml={descriptionHTMLEn}
              />
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="file" value="ატვირთე სურათი" />
                </div>
                <FileInput
                  id="file"
                  helperText={student.imageUrl ? student.imageUrl : "სურათი"}
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </div>
          {errorMessages &&
            errorMessages?.map((message) => (
              <h3 className="text-main-red font-bold">{message}</h3>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="main-red"
            onClick={() => setOpenModal(false)}
            disabled={buttonsDisabled}
          >
            დახურვა
          </Button>
          <Button color="gray" onClick={handleSave} disabled={buttonsDisabled}>
            შენახვა
          </Button>
          <Button
            color={"warning"}
            onClick={() => handleDelete(student.id)}
            disabled={buttonsDisabled}
          >
            წაშლა
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditStudentModal;
