import React from "react";

interface EmployeeCardPreview {
  imageUrl: string;
  name: string;
  role: string;
}

function EmployeeCard({ name, imageUrl, role }: EmployeeCardPreview) {
  return (
    <>
      <div className="flex flex-col gap-4">
        <img
          src={imageUrl}
          alt="employee"
          className="w-full object-cover max-h-76 object-top rounded-lg"
        />
        <div className="">
          <h2 className="text-white font-bold text-sm lg:text-xl">{name}</h2>
          <h4 className="text-gray-200 text-xs">{role}</h4>
        </div>
      </div>
    </>
  );
}

export default EmployeeCard;
