import React, { useState } from "react";
import { Mail } from "../../../../interfaces/Mail";
import { Table } from "flowbite-react";
import ViewMailModal from "../Modals/ViewMailModal";

interface AdminMailTableRowProps extends Mail {
  onReplyClick: () => void; // Add onReplyClick prop type
}

const AdminMailTableRow: React.FC<AdminMailTableRowProps> = ({
  id,
  name,
  email,
  message,
  phoneNumber,
  dateCreated,
  onReplyClick, // Destructure the onReplyClick prop
}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {/* Table row displaying mail details */}
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
        <Table.Cell>{new Date(dateCreated).toLocaleDateString()}</Table.Cell>
        <Table.Cell>{name}</Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {email}
        </Table.Cell>
        <Table.Cell>{phoneNumber}</Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {message.slice(0, 20) + "..."}
        </Table.Cell>
        <Table.Cell>
          <a
            onClick={() => setOpenModal(true)}
            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 hover:cursor-pointer"
          >
            ნახვა
          </a>
        </Table.Cell>
      </Table.Row>

      {/* Modal for viewing mail details */}
      <ViewMailModal
        mail={{ id, name, email, message, phoneNumber, dateCreated }} // Pass the mail details to the modal
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default AdminMailTableRow;
