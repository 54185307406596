import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles

interface HtmlEditorProps {
  onChange: (html: string) => void;
  initialHtml?: string; // Optional prop for initial HTML content
}

const HtmlEditor: React.FC<HtmlEditorProps> = ({ onChange, initialHtml = '' }) => {
  const [editorHtml, setEditorHtml] = useState<string>(initialHtml);

  useEffect(() => {
    setEditorHtml(initialHtml); // Set initial HTML content when the component mounts or when initialHtml changes
  }, [initialHtml]);

  const handleChange = (html: string) => {
    setEditorHtml(html);
    onChange(html); // Pass the HTML string to the parent component or callback
  };

  const modules = {
    toolbar: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' },
      { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      ['clean']
    ],
  };

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video'
  ];

  return (
    <div>
      <ReactQuill
        value={editorHtml}
        onChange={handleChange}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default HtmlEditor;
