import React, { useEffect, useState } from "react";
import HeaderSpace from "../../components/Shared/HeaderSpace/HeaderSpace";
import Container from "../../components/Shared/Container/Container";
import ServiceHorizontalPreview from "../../components/Previews/ServiceHorizontalPreview";
import {
  collection,
  DocumentData,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import Spinner from "../../components/Shared/Spinner/Spinner";
import { Service } from "../../interfaces/Service";
import { db } from "../../firebase/firebaseConfig";
import { useTranslation } from "react-i18next"; // Import useTranslation
import SquarePreviewService from "../../components/SquarePreviewService/SquarePreviewService";

function EnglishCenter() {
  const { t, i18n } = useTranslation(); // Initialize translation hook with i18n for language detection
  const [services, setServices] = useState<Service[]>();

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "englishCenter"));
      const items = querySnapshot.docs.map(
        (doc: QueryDocumentSnapshot<DocumentData>) =>
          ({
            id: doc.id,
            ...doc.data(),
          } as Service)
      );
      setServices(items);
    };

    fetchData();
  }, []);

  if (!services) {
    return <Spinner />;
  }

  return (
    <div
      className="relative bg-repeat"
      style={{
        backgroundImage: `url('/images/backgrounds/background-dots-01.png')`,
        backgroundSize: "150px 150px",
      }}
    >
      <HeaderSpace />
      <Container className="py-4 lg:py-12 flex flex-col gap-8 lg:gap-16">
        <div className="flex flex-col">
          <div className="flex flex-col text-center">
            <h3 className="text-main-blue font-bold">
              {/* {t("getWhatYouNeed")} */}
            </h3>
            <h2 className="text-main-red font-bold text-4xl">
              {t("englishCenter")}
            </h2>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 lg:gap-32">
          <div className="flex flex-col gap-4">
            {/* <h3
              className="font-bold text-main-blue text-center lg:text-left text-2xl lg:text-3xl"
              dangerouslySetInnerHTML={{ __html: t("seeOurEnglishCenter") }} // HTML translation for studying abroad
            /> */}
            {i18n.language === "en" ? (
              <div>
                {/* <div className="font-bold text-main-blue text-center text-2xl lg:text-3xl lg:text-center mb-4">
                  ELL English Centre
                </div> */}
                <div className="lg:text-left list-disc translate-x-4 flex flex-col gap-4 text-sm lg:text-[16px]">
                  <br />
                  ELL English Centre was founded in 2005 and since then it has
                  been operating as English Language Preparation Centre.
                  <br />
                  <br />
                  ELL Language Centre is one of the most successful centres
                  which was granted a certificate by Cambridge English Language
                  Assessment body as official Cambridge English Preparation
                  Centre.
                  <br />
                  <br />
                  In recognition of ELL success, in 2023 ELL Language Centre has
                  become a premium Level holder of British Council Bonus
                  Programme Addvantage, designed for partner Organizations which
                  prepare students for Cambridge Exams. Prior to this, from 2013
                  - 2022 ELL was Silver and Gold level holder.
                  <br />
                  <br />
                  The teaching staff at ELL language school is highly-qualified
                  professionals with lots of experience and enthusiasm. There is
                  a continuous program of staff development and training to make
                  sure that ELL school uses up-to-date teaching methods and
                  materials. Their enthusiasm and commitment ensures that you
                  make maximum progress and they always give you help and advice
                  when you need it.
                  <br />
                  <br />
                  ELL Language Centre provides English Language courses in
                  General English as well as International Exam preparation
                  courses in Groups and Individuals.
                  <br />
                  <br />
                  General English Courses: Beginner, Elementary, Pre
                  Intermediate, Intermediate, Upper Intermediate, Advanced
                  <br />
                  <br />
                  ELL Exam preparation courses: KET, PET, FCE, CAE, IELTS, TOEFL, SAT , GMAT
                </div>
              </div>
            ) : (
              <div>
                {/* <div className="font-bold text-main-blue text-center text-2xl lg:text-3xl lg:text-center mb-4">
                  ELL -ის ინგლისური ენის ცენტრი
                </div> */}
                <div className="lg:text-left list-disc translate-x-4 flex flex-col gap-4 text-sm lg:text-[16px]">
                  <br />
                  ELL - ის ინგლისური ენის ცენტრი დაარსდა 2005 წელს და
                  წარმოადგენს ენის შემსწავლელ სკოლას, რომელიც გთავაზობთ
                  ინგლისური ენის მოსამზადებელ კურსებს ნებისმიერი ასაკისთვის.
                  <br />
                  <br />
                  ELL -ის ინგლისური ენის ცენტრი აღიარებულია Cambridge English
                  Assessment ის მიერ როგორც საერთაშორისო გამოცდებისათვის
                  მოსამზადებელ საუკეთესო ცენტრი საქართველოში და 2015 წელს
                  მიენიჭა სერტიფიცატი Cambridge English Exam Preparation Centre
                  კემბრიჯის საგამოცდო ცენტრის მიერ საქმიანობაში მიღწეული
                  წარმატებებისათვის.
                  <br />
                  <br />
                  ELL -ს გუნდის კიდევ ერთი აღიარებაა ბრიტანეთის საბჭოს კემბრიჯის
                  გამოცდების ბონუს პროგრამის Advantage -ის პრემიუმ დონე, რომელიც
                  ELL -ს 2023 წელს მიენიჭა. მანამდე, 2013-2022 წლებში, ცენტრი
                  გახლდათ ამ პროგრამის ვერცხლის, ხოლო შემდგომში ოქროს დონის
                  მფლობელი. Advantage ბრიტანეთის საბჭოს ბონუს პროგრამაა იმ
                  პარტიორი ორგანიზაციებისთვის, რომლებიც ამზადებენ და
                  არეგისტრირებენ კანდიდატებს კემბრიჯის საერთაშორისო
                  გამოცდებისთვის. ELL-ის ინგლისური ენის ცენტრში ასწავლიან
                  მაღალკვალიფიციური პედაგოგები რომელთაც გააჩნიათ სხვადასხვა
                  საერთაშორისო სერთიფიკატები, ხანგრძლივი გამოცდილება და
                  ენთუზიაზმი. ELL -ში შემუშავებულია უწყვეტი პროგრამა აკადემიური
                  პრესონალის კვალიფიკაციის ამაღლებისა და განვითარებისათვის, რათა
                  ვუზრუნველყოთ სკოლაში უახლესი სასწავლო მეთოდიკის და მასალის
                  გამოყენება. მასწავლებელთა ენთუზიაზმისა და თავდადების შედეგად
                  ჩვენი სტუდენტები აღწევენ მაქსიმალურ შედეგებს.
                  <br />
                  <br />
                  ELL –ის ინგლირუსი ენის ცენტრი გთავაზობთ ზოგადი ინგლისური ენისა
                  და საერთაშრისო სერთფიკატებისათვის მოსამზადებელ ჯგუფურ და
                  ინდივიდუალურ კურსებს
                  <br />
                  <br />
                  ზოგადი ინგლისურის კურსები: Beginner, Elementary,
                  Pre-Intermediate, Intermediate, Upper Intermediate, Advanced
                  საერთაშორისო საგამოცდო კურსები: KET, PET, FCE, CAE, IELTS,
                  TOEFL, SAT, GMAT.
                </div>
              </div>
            )}
          </div>
          <div className={`grid grid-cols-1 lg:grid-cols-${services.length >= 6 ? '1' : '2'} gap-2`}>
            {services &&
              services
                .sort((a, b) => a.order - b.order)
                .map((service) => (
                  <SquarePreviewService service={service} key={service.id} />
                ))}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default EnglishCenter;
