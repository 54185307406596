import { Table } from 'flowbite-react'
import React, { useState } from 'react'
import { Student } from '../../../../interfaces/Student'
import EditStudentModal from '../Modals/EditStudentModal'

function AdminStudentTableRow(student:Student) {
    const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
        <Table.Cell>
          <img
            src={student.imageUrl}
            alt="empl"
            className="w-16 max-h-16 rounded-full"
          />
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {student.name}
        </Table.Cell>
        <Table.Cell>{student.jobs.map((job) => job + ",")}</Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {/* <div dangerouslySetInnerHTML={{__html:student.descriptionHTML}} /> */}
          {student.descriptionHTML.slice(0,20)}
        </Table.Cell>
        <Table.Cell>
          <a
            onClick={() => setOpenModal(true)}
            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 hover:cursor-pointer"
          >
            შევცლა
          </a>
        </Table.Cell>
      </Table.Row>
      <EditStudentModal
      student={student}
      openModal={openModal}
      setOpenModal={setOpenModal}
      />
    </>
  )
}

export default AdminStudentTableRow