// i18n.ts (TypeScript)
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import ka from './locales/ka.json';

// Initialize i18n
i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      ka: {
        translation: ka,
      },
    },
    lng: 'ka', // Default language
    fallbackLng: 'en', // Use this if the current language is not found
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
