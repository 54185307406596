import { Table } from "flowbite-react";
import React, { useState } from "react";
import { University } from "../../../../interfaces/University";
import EditUniversityModal from "../Modals/EditUniversityModal";

function AdminUniversitiesTableRow(university: University) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
        <Table.Cell>
          <img src={`https://flagsapi.com/${university.slug.toLocaleUpperCase()}/flat/64.png`} />
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {university.name}
        </Table.Cell>
        <Table.Cell>{university.slug}</Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {university.region}
        </Table.Cell>
        <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
          {university.bodyHTML.slice(0,20)}
        </Table.Cell>
        <Table.Cell>
          <a
            onClick={() => setOpenModal(true)}
            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 hover:cursor-pointer"
          >
            შევცლა
          </a>
        </Table.Cell>
      </Table.Row>
      <EditUniversityModal
        university={university}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
}

export default AdminUniversitiesTableRow;
