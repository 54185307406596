import { Button, Label, Modal, TextInput, FileInput } from "flowbite-react";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { object, string, number } from "yup";
import { deleteDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../../../firebase/firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FarePhoto } from "../../../../interfaces/FaresPhoto";

interface EditFarePhotoModalProps {
  farePhoto: FarePhoto;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditFarePhotoModal: React.FC<EditFarePhotoModalProps> = ({
  farePhoto,
  openModal,
  setOpenModal,
}) => {
  const [imageUrl, setImageUrl] = useState(farePhoto.imageUrl || "");
  const [order, setOrder] = useState<number>(farePhoto.order || 0);
  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();

  const farePhotoSchema = object({
    imageUrl: string().required("სურათის URL აუცილებელია!"),
    order: number()
      .required("რაოდენობა აუცილებელია!")
      .typeError("რაოდენობა უნდა იყოს ციფრი."),
  });

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploading(true);
      const storageRef = ref(storage, `faresPhotos/${farePhoto.id}/image`);

      try {
        await uploadBytes(storageRef, file);
        const downloadUrl = await getDownloadURL(storageRef);
        setImageUrl(downloadUrl);
      } catch (error) {
        console.error("Image upload failed:", error);
        setErrorMessages(["სურათის ატვირთვა ვერ მოხერხდა. გთხოვთ, ცადოთ თავიდან."]);
      } finally {
        setUploading(false);
      }
    }
  };

  const handleSave = async () => {
    if (!farePhoto) {
      setErrorMessages(["არცერთი ფარის ფოტო არ არის არჩეული შესაცვლელად."]);
      return;
    }

    try {
      await farePhotoSchema.validate({ imageUrl, order });

      const updatedFarePhoto: FarePhoto = {
        id: farePhoto.id,
        imageUrl,
        order,
      };

      const farePhotoDocRef = doc(db, "faresPhotos", updatedFarePhoto.id);
      await setDoc(farePhotoDocRef, updatedFarePhoto, { merge: true });
      await updateDoc(farePhotoDocRef, updatedFarePhoto as { [x: string]: any });

      setOpenModal(false);
      navigate(0);
    } catch (e: any) {
      let messages = e.errors;
      setErrorMessages(messages);
    }
  };

  const handleDelete = async (farePhotoId: string) => {
    setButtonsDisabled(true);
    try {
      const farePhotoDocRef = doc(db, "farePhotos", farePhotoId);
      await deleteDoc(farePhotoDocRef);
      navigate(0);
    } catch (error) {
      console.error("დოკუმენტის წაშლის შეცდომა: ", error);
    }
    setButtonsDisabled(false);
  };

  return (
    <Modal
      dismissible
      show={openModal}
      onClose={() => setOpenModal(false)}
      className="font-mtavvruli"
    >
      <Modal.Header className="text-main-blue font-bold">ღონისძიებების ფოტოს რედაქტირება</Modal.Header>
      <Modal.Body>
        <div className="space-y-6 w-full">
          <div className="flex flex-col gap-4">
            {/* File Input for Image Upload */}
            <div>
              <div className="mb-2 block">
                <Label htmlFor="file" value="ატვირთე სურათი" />
              </div>
              <FileInput
                id="file"
                helperText={imageUrl ? imageUrl : "არჩევა ან ატვირთვა"}
                onChange={handleFileChange}
              />
            </div>
            {/* Order Input */}
            <div>
              <div className="mb-2 block">
                <Label htmlFor="order" value="რიგი" />
              </div>
              <TextInput
                id="order"
                type="number"
                value={order || ""}
                onChange={(e) => setOrder(Number(e.target.value))}
                required
              />
            </div>
          </div>
        </div>
        {errorMessages &&
          errorMessages.map((message) => (
            <h3 className="text-main-red font-bold" key={message}>
              {message}
            </h3>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="main-red"
          onClick={() => setOpenModal(false)}
          disabled={buttonsDisabled || uploading}
        >
          დახურვა
        </Button>
        <Button color="gray" onClick={handleSave} disabled={buttonsDisabled || uploading}>
          {uploading ? "ატვირთვა..." : "შენახვა"}
        </Button>
        <Button
          color="warning"
          onClick={() => handleDelete(farePhoto.id)}
          disabled={buttonsDisabled || uploading}
        >
          წაშლა
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditFarePhotoModal;
