import React, { useEffect, useState } from "react";
import HeaderSpace from "../../../components/Shared/HeaderSpace/HeaderSpace";
import Sidebar from "../../../components/Shared/Sidebar/Sidebar";
import { Table, Button, Pagination } from "flowbite-react";
import {
  collection,
  DocumentData,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";
import { Mail } from "../../../interfaces/Mail";
import AdminMailTableRow from "./Table/AdminMailTableRow";
import HtmlEditor from "../../../components/HtmlEditor/HtmlEditor"; // Import the HtmlEditor component

function AdminMails() {
  const [mails, setMails] = useState<Mail[]>([]);
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const [itemsPerPage, setItemsPerPage] = useState(20); // Number of items per page
  const [selectedMail, setSelectedMail] = useState<Mail | null>(null); // Selected mail for reply
  const [replyHtml, setReplyHtml] = useState<string>(""); // State to hold the reply content in HTML

  const fetchData = async () => {
    const querySnapshot = await getDocs(collection(db, "mails"));
    const items = querySnapshot.docs.map(
      (doc: QueryDocumentSnapshot<DocumentData>) =>
        ({
          id: doc.id,
          ...doc.data(),
        } as Mail)
    );
    setMails(items);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentMails = mails
    .sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime())
    .slice(indexOfFirstItem, indexOfLastItem);

  // Calculate total pages
  const totalPages = Math.ceil(mails.length / itemsPerPage);

  // Handle page change
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  // Handle selecting a mail to reply to
  const handleReplyClick = (mail: Mail) => {
    setSelectedMail(mail);
    setReplyHtml(""); // Clear the editor content when selecting a new mail
  };

  // Handle form submission for replying
  const handleSendReply = () => {
    if (selectedMail) {
      console.log("Replying to:", selectedMail);
      console.log("Reply Message in HTML:", replyHtml);

      // Here, you can add the logic to send the reply to the email using a backend service or an API

      // Reset the editor and selected mail after sending the email
      setReplyHtml("");
      setSelectedMail(null);
    }
  };

  return (
    <>
      <HeaderSpace className="bg-white h-20 lg:h-36" />
      <div className="flex flex-col lg:flex-row">
        <Sidebar />
        <div className="flex-1 py-12">
          <div className="w-10/12 mx-auto flex flex-col gap-8">
            <div className="flex flex-col lg:flex-row justify-between gap-4">
              <h2 className="text-main-red font-bold text-3xl text-center lg:text-left lg:text-5xl">
                შემოსული წერილები
              </h2>
            </div>
            <div className="overflow-x-auto">
              <Table striped>
                <Table.Head>
                  <Table.HeadCell>შექმნის თარიღი</Table.HeadCell>
                  <Table.HeadCell>სახელი</Table.HeadCell>
                  <Table.HeadCell>ელ. ფოსტა</Table.HeadCell>
                  <Table.HeadCell>ტელეფონის ნომერი</Table.HeadCell>
                  <Table.HeadCell>მესიჯი</Table.HeadCell>
                  <Table.HeadCell>
                    <span className="sr-only">Reply</span>
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {currentMails.map((mail) => (
                    <AdminMailTableRow
                      id={mail.id}
                      name={mail.name}
                      email={mail.email}
                      message={mail.message}
                      phoneNumber={mail.phoneNumber}
                      dateCreated={mail.dateCreated}
                      key={mail.id}
                      onReplyClick={() => handleReplyClick(mail)}
                    />
                  ))}
                </Table.Body>
              </Table>
            </div>

            {/* Pagination Controls */}
            <div className="flex justify-center mt-4">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={(page) => handlePageChange(page)}
                showIcons
                previousLabel="წინა"
                nextLabel="შემდეგი"
                className="pagination"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminMails;
