import {
  Button,
  FileInput,
  Label,
  Modal,
  Select,
  TextInput,
} from "flowbite-react";
import React, { useState } from "react";
import { University } from "../../../../interfaces/University";
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../../../firebase/firebaseConfig";
import { object, string } from "yup";
import { useNavigate } from "react-router";
import HtmlEditor from "../../../../components/HtmlEditor/HtmlEditor";
import { REGIONS } from "../../../../enums/regions";

const { v4: uuidv4 } = require("uuid");

interface AddUniversityModalProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddUniversityModal({
  openModal,
  setOpenModal,
}: AddUniversityModalProps) {
  const [name, setName] = useState("");
  const [nameEn, setNameEn] = useState(""); // English name
  const [slug, setSlug] = useState("");
  const [region, setRegion] = useState(REGIONS.Europe);
  const [body, setBody] = useState("");
  const [bodyEn, setBodyEn] = useState(""); // English body
  const [errorMessages, setErrorMessages] = useState<string[] | null>(null);
  const navigate = useNavigate();

  let universitySchema = object({
    name: string().required("სახელი აუცილებელია!"),
    nameEn: string().required("Name in English is required!"),
    slug: string()
      .required("სლაგი აუცილებელია!")
      .length(2, "სლაგი უნდა იყოს 2 სიმბოლოიანი. (მაგ: GB, US, FR)"),
    region: string().required("რეგიონი აუცილებელია!"),
    body: string().required("ტანი აუცილებელია"),
    bodyEn: string().required("Body in English is required!"),
  });

  const handleSave = async () => {
    try {
      await universitySchema.validate({
        name: name,
        nameEn: nameEn,
        slug,
        region,
        body,
        bodyEn,
      });

      const newUni: University = {
        id: uuidv4(),
        name,
        nameEn,
        bodyHTML: body,
        bodyHTMLEn: bodyEn,
        region,
        slug,
      };

      const myCollection = collection(db, "universities");
      const myDocRef = doc(myCollection, newUni.id);

      // Add or update the document
      await setDoc(myDocRef, newUni);

      setOpenModal(false);
      navigate(0);
    } catch (e: any) {
      let messages = e.errors;
      setErrorMessages(messages);
    }
  };

  return (
    <>
      <Modal
        dismissible
        show={openModal}
        onClose={() => setOpenModal(false)}
        className="font-mtavvruli"
      >
        <Modal.Header className="text-main-blue font-bold">
          ახალი უნივერსიტეტის ქვეყნის დამატება
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6 w-full">
            <div className="flex flex-col gap-4">
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="სახელი" />
                </div>
                <TextInput
                  id="name"
                  type="text"
                  sizing="md"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="nameEn" value="სახელი (ინგლისურად)" />
                </div>
                <TextInput
                  id="nameEn"
                  type="text"
                  sizing="md"
                  value={nameEn}
                  onChange={(e) => setNameEn(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="base" value="სლაგი" />
                </div>
                <TextInput
                  id="base"
                  type="text"
                  sizing="md"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="countries" value="აირჩიე რეგიონი" />
                </div>
                <Select
                  id="countries"
                  value={region}
                  onChange={(e) => setRegion(e.target.value)}
                  required
                >
                  <option>{REGIONS.Europe}</option>
                  <option>{REGIONS.Asia}</option>
                  <option>{REGIONS.NorthAmerica}</option>
                  <option>{REGIONS.SouthAmerica}</option>
                  <option>{REGIONS.Oceania}</option>
                </Select>
              </div>
              <HtmlEditor onChange={setBody} initialHtml={body} />
              <HtmlEditor
                onChange={setBodyEn}
                initialHtml={bodyEn}
              />
            </div>
          </div>
          {errorMessages &&
            errorMessages.map((message, index) => (
              <h3 key={index} className="text-main-red font-bold">
                {message}
              </h3>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button color="main-red" onClick={() => setOpenModal(false)}>
            დახურვა
          </Button>
          <Button color="gray" onClick={handleSave}>
            შენახვა
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddUniversityModal;
