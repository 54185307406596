import React, { useState } from "react";
import { Button, FileInput, Label, Modal, TextInput } from "flowbite-react";
import { useNavigate } from "react-router";
import { object, string } from "yup";
import { db } from "../../../../firebase/firebaseConfig";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { Certificate } from "../../../../interfaces/Certificate";

const { v4: uuidv4 } = require("uuid");

interface AddCertificateModalProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddCertificateModal({ openModal, setOpenModal }: AddCertificateModalProps) {
  const [name, setName] = useState("");
  const [nameEn, setNameEn] = useState(""); // English name
  const [imageUrl, setImageUrl] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const navigate = useNavigate();

  const certificateSchema = object({
    name: string().required("სერტიფიკატის სახელი აუცილებელია!"),
    nameEn: string().required("Certificate name (English) is required!"),
    imageUrl: string().required("სურათი აუცილებელია!"),
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleSave = async () => {
    try {
      let uploadedImageUrl = "";

      if (file) {
        const storage = getStorage();
        const storageRef = ref(storage, `certificates/${file.name}`);
        await uploadBytes(storageRef, file);
        uploadedImageUrl = await getDownloadURL(storageRef);
        setImageUrl(uploadedImageUrl);
      }

      await certificateSchema.validate({
        name,
        nameEn,
        imageUrl: uploadedImageUrl,
      });

      const newCertificate: Certificate = {
        id: uuidv4(),
        name,
        nameEn,
        imageUrl: uploadedImageUrl,
      };

      const certificateDocRef = doc(db, "certificates", newCertificate.id);
      await setDoc(certificateDocRef, newCertificate);

      setOpenModal(false);
      navigate(0); // Refresh the page or navigate as needed
    } catch (e: any) {
      console.error(e);
      setErrorMessages(e.errors || ["An error occurred while saving."]);
    }
  };

  return (
    <Modal
      dismissible
      show={openModal}
      onClose={() => setOpenModal(false)}
      className="font-mtavvruli"
    >
      <Modal.Header className="text-main-blue font-bold">
        დაამატე <span className="text-main-red">სერტიფიკატი</span>
      </Modal.Header>
      <Modal.Body>
        <div className="space-y-6 w-full">
          <div className="flex flex-col gap-4">
            {/* Georgian Name */}
            <div>
              <Label htmlFor="name" value="სერტიფიკატის სახელი" />
              <TextInput
                id="name"
                type="text"
                sizing="md"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            {/* English Name */}
            <div>
              <Label htmlFor="nameEn" value="Certificate Name (English)" />
              <TextInput
                id="nameEn"
                type="text"
                sizing="md"
                value={nameEn}
                onChange={(e) => setNameEn(e.target.value)}
              />
            </div>

            {/* Image Upload */}
            <div>
              <Label htmlFor="file" value="ატვირთე სურათი" />
              <FileInput
                id="file"
                helperText={imageUrl ? imageUrl : "სურათი"}
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>
        {errorMessages &&
          errorMessages.map((message, index) => (
            <h3 key={index} className="text-main-red font-bold">
              {message}
            </h3>
          ))}
      </Modal.Body>
      <Modal.Footer>
        <Button
          color="main-red"
          onClick={() => setOpenModal(false)}
          disabled={buttonsDisabled}
        >
          დახურვა
        </Button>
        <Button color="gray" onClick={handleSave} disabled={buttonsDisabled}>
          დამატება
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddCertificateModal;
