import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { Button, FileInput, Label, Modal, TextInput, Spinner } from "flowbite-react"; // Added Spinner for loading state
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { object, string } from "yup";
import { Student } from "../../../../interfaces/Student";
import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../../../firebase/firebaseConfig";
import HtmlEditor from "../../../../components/HtmlEditor/HtmlEditor";

const { v4: uuidv4 } = require("uuid");

interface AddStudentModal {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddStudentModal({ openModal, setOpenModal }: AddStudentModal) {
  const [name, setName] = useState<string>("");
  const [nameEn, setNameEn] = useState<string>(""); // English name field
  const [jobsStr, setJobsStr] = useState<string>("");
  const [descriptionHTML, setDescriptionHTML] = useState<string>("");
  const [descriptionHTMLEn, setDescriptionHTMLEn] = useState<string>(""); // English description field

  const [file, setFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>("");

  const [errorMessages, setErrorMessages] = useState<string[]>();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [loading, setLoading] = useState(false); // Added loading state

  const navigate = useNavigate();

  let studentSchema = object({
    name: string().required("სახელი აუცილებელია!"),
    nameEn: string().required("English name is required!"),
    jobsStr: string().required("სამსახურები ან უნივერსიტეტები აუცილებელია!"),
    imageUrl: string().required("სურათი აუცილებელია!"),
    descriptionHTML: string().required("აღწერა აუცილებელია!"),
    descriptionHTMLEn: string().required("English description is required!"),
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
      setImagePreview(URL.createObjectURL(event.target.files[0])); // Set image preview
    }
  };

  const handleSave = async () => {
    setButtonsDisabled(true);
    setLoading(true);
    setErrorMessages([]);

    try {
      let imageUrl = "";

      if (file) {
        const storage = getStorage();
        const storageRef = ref(storage, `images/${file.name}`);
        await uploadBytes(storageRef, file);
        imageUrl = await getDownloadURL(storageRef);
      }

      await studentSchema.validate({
        name: name,
        nameEn: nameEn,
        jobsStr,
        imageUrl: imageUrl,
        descriptionHTML: descriptionHTML,
        descriptionHTMLEn: descriptionHTMLEn,
      });

      const newStudent: Student = {
        id: uuidv4(),
        name,
        nameEn,
        imageUrl,
        jobs: jobsStr.split(",").map((job) => job.trim()),
        descriptionHTML: descriptionHTML,
        descriptionHTMLEn: descriptionHTMLEn,
      };

      const myCollection = collection(db, "students");
      const myDocRef = doc(myCollection, newStudent.id);

      // Add or update the document
      await setDoc(myDocRef, newStudent);

      setOpenModal(false);
      setLoading(false);
      navigate(0); // Optionally refresh the page to show the new student
    } catch (e: any) {
      console.error(e);
      let messages = e.errors || ["დაფიქსირდა შეცდომა"];
      setErrorMessages(messages);
      setLoading(false);
    } finally {
      setButtonsDisabled(false);
    }
  };

  const resetForm = () => {
    setName("");
    setNameEn("");
    setJobsStr("");
    setDescriptionHTML("");
    setDescriptionHTMLEn("");
    setFile(null);
    setImagePreview("");
    setErrorMessages([]);
  };

  return (
    <>
      <Modal
        dismissible
        show={openModal}
        onClose={() => {
          setOpenModal(false);
          resetForm(); // Reset form when modal is closed
        }}
        className="font-mtavvruli"
      >
        <Modal.Header className="text-main-blue font-bold">
          დაამატე სტუდენტი
        </Modal.Header>
        <Modal.Body>
          <div className="space-y-6 w-full">
            <div className="flex flex-col gap-4">
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="name" value="სახელი" />
                </div>
                <TextInput
                  id="name"
                  type="text"
                  sizing="md"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="nameEn" value="სახელი (ინგლისურად)" />
                </div>
                <TextInput
                  id="nameEn"
                  type="text"
                  sizing="md"
                  value={nameEn}
                  onChange={(e) => setNameEn(e.target.value)}
                />
              </div>
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="base" value="სამსახურები და უნივერსიტეტები" />
                </div>
                <TextInput
                  id="base"
                  type="text"
                  sizing="md"
                  value={jobsStr}
                  onChange={(e) => setJobsStr(e.target.value)}
                />
              </div>
              <HtmlEditor
                onChange={setDescriptionHTML}
                initialHtml={descriptionHTML}
              />
              <HtmlEditor
                onChange={setDescriptionHTMLEn}
                initialHtml={descriptionHTMLEn}
              />
              <div>
                <div className="mb-2 block">
                  <Label htmlFor="file" value="ატვირთე სურათი" />
                </div>
                <FileInput
                  id="file"
                  helperText={"სურათი"}
                  onChange={handleFileChange}
                />
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    className="mt-4 w-40 h-40 object-cover rounded-lg"
                  />
                )}
              </div>
            </div>
          </div>
          {errorMessages &&
            errorMessages?.map((message, index) => (
              <h3 key={index} className="text-main-red font-bold">
                {message}
              </h3>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            color="main-red"
            onClick={() => {
              setOpenModal(false);
              resetForm(); // Reset form when closing modal
            }}
            disabled={buttonsDisabled}
          >
            დახურვა
          </Button>
          <Button
            color="gray"
            onClick={handleSave}
            disabled={buttonsDisabled || loading}
          >
            {loading ? <Spinner /> : "შენახვა"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddStudentModal;
