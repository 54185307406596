import React, { useState } from "react";
import { Table } from "flowbite-react";
import EditFarePhotoModal from "../Modals/EditFarePhotoModal";

interface FarePhoto {
  id: string;
  imageUrl: string;
  order: number;
}

interface AdminFarePhotoTableRowProps extends FarePhoto {}

const AdminFarePhotoTableRow: React.FC<AdminFarePhotoTableRowProps> = ({
  id,
  imageUrl,
  order,
}) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      {/* Table row displaying fare photo details */}
      <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
        <Table.Cell>
          <img
            src={imageUrl}
            alt={`Fare Photo ${id}`}
            className="h-12 w-24 object-cover"
          />
        </Table.Cell>
        <Table.Cell>{order}</Table.Cell>
        <Table.Cell>
          <a
            onClick={() => setOpenModal(true)}
            className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 hover:cursor-pointer"
          >
            შეცვლა
          </a>
        </Table.Cell>
      </Table.Row>

      {/* Modal for editing fare photo details */}
      <EditFarePhotoModal
        farePhoto={{ id, imageUrl, order }} // Pass the fare photo details to the modal
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default AdminFarePhotoTableRow;
