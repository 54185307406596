import React, { useState, useEffect } from "react";
import { Button, MegaMenu, Navbar as Nav } from "flowbite-react";
import Container from "../Container/Container";
import { scrollToContact } from "../../../utils";
import { Link, useLocation } from "react-router-dom";
import LanguageSelector from "../../LanguageSelector/LanguageSelector";
import { useTranslation } from "react-i18next";

function Navbar() {
  const [navCollapsed, setNavCollapsed] = useState(false);
  const location = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setNavCollapsed(false); // Close the navbar on route change
  }, [location]);

  const isHomepage = location.pathname === "/";
  const isEnglish = i18n.language === "en";

  return (
    <>
      {/* MOBILE NAVBAR */}
      <div className="xl:hidden">
        <div className="text-xs lg:text-sm p-2 flex justify-center bg-main-red text-white">
          {t("websiteIsUnderConstruction")}
        </div>
        <div
          className={`absolute w-full z-50 left-0 ${navCollapsed
            ? "bg-main-blue border-b border-2 border-gray-400 rounded-b-3xl"
            : "bg-transparent"
            } transition-all duration-300`}
        >
          <Container>
            <Nav
              fluid
              rounded
              className="justify-between bg-opacity-0 lg:bg-transparent"
            >
              <div
                className={`w-full grid ${!navCollapsed ? "grid-cols-2" : "grid-cols-3"
                  } md:flex md:justify-between`}
              >
                <Link to="/" onClick={() => setNavCollapsed(false)}>
                  <img
                    src={`${navCollapsed
                      ? "/images/logos/ell-logo-white.png"
                      : "/images/logos/ell-logo.png"
                      }`}
                    className="mr-3 h-16 md:h-24"
                    alt="ell logo"
                  />
                </Link>
                <div
                  className={`${!navCollapsed && "hidden"
                    } flex flex-col justify-center items-center`}
                >
                  <LanguageSelector />
                </div>
                <div className="flex items-center justify-end">
                  <Nav.Toggle
                    className="bg-white"
                    onClick={() => setNavCollapsed(!navCollapsed)}
                  />
                </div>
                <div className="hidden md:grid md:grid-cols-5">
                  <Link
                    to="/services"
                    className="flex text-sm justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                    onClick={() => setNavCollapsed(false)}
                  >
                    {t("services")}
                  </Link>
                  <Link
                    to="/universities"
                    className="flex text-sm justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                    onClick={() => setNavCollapsed(false)}
                  >
                    {t("partners")}
                  </Link>
                  <Link
                    to="/students"
                    className="flex text-sm justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                    onClick={() => setNavCollapsed(false)}
                  >
                    {t("students")}
                  </Link>
                  <Link
                    to="/blog"
                    className="flex text-sm justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                    onClick={() => setNavCollapsed(false)}
                  >
                    {t("blog")}
                  </Link>
                  {/* <Link
                    to="/faq"
                    className="flex text-sm justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                    onClick={() => setNavCollapsed(false)}
                  >
                    {t("faq")}
                  </Link> */}
                  <Link
                    to="/about"
                    className="flex text-sm justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                    onClick={() => setNavCollapsed(false)}
                  >
                    {t("about")}
                  </Link>
                  {/* <Link
                    to="/contact"
                    className="flex text-sm justify-center items-center text-main-blue hover:text-cyan-700 font-semibold py-1"
                    onClick={() => setNavCollapsed(false)}
                  >
                    {t("contact")}
                  </Link> */}
                  <div className="flex flex-col justify-center">
                    <Link to="/contact" >
                      <Button color="main-red" size={"sm"} className="h-10">
                        {t("contactUs")}
                      </Button>
                    </Link>
                  </div>
                  <div className="flex items-center justify-center">
                    <LanguageSelector />
                  </div>
                </div>
              </div>
              <Nav.Collapse
                className={`justify-center items-center bg-darkBackground md:hidden lg:bg-transparent pb-2 ${navCollapsed ? "block bg-main-blue rounded-b-lg " : "hidden"
                  }`}
              >
                <Link
                  to="/services"
                  className="flex justify-center items-center text-white hover:text-gray-400 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("studyAbroad")}
                </Link>
                <Link
                  to="/universities"
                  className="flex justify-center items-center text-white hover:text-gray-400 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("partners")}
                </Link>
                <Link
                  to="/students"
                  className="flex justify-center items-center  text-white hover:text-gray-400 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("students")}
                </Link>
                {/* <Link
                  to="/faq"
                  className="flex justify-center items-center  text-white hover:text-gray-400 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("faq")}
                </Link> */}
                <Link
                  to="/about"
                  className="flex justify-center items-center text-white hover:text-gray-400 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("about")}
                </Link>
                <Link
                  to="/fares"
                  className="flex justify-center items-center text-white hover:text-gray-400 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("fares")}
                </Link>
                {/* <Link
                  to="/contact"
                  className="flex justify-center items-center text-white hover:text-gray-400 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("contact")}
                </Link> */}
                <Link
                  to="/blog"
                  className="flex justify-center items-center text-white hover:text-gray-400 font-semibold py-1"
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("blog")}
                </Link>
                <div className="hidden lg:flex justify-center">
                  <LanguageSelector />
                </div>
                <Link to="/contact" className="flex justify-center items-center mt-4">
                  <Button color="main-red" className="w-full">{t("contactUs")}</Button>
                </Link>
              </Nav.Collapse>
            </Nav>
          </Container>
        </div>
      </div>

      {/* DESKTOP NAVBAR */}
      <div className="hidden xl:block">
        <div className="text-xs lg:text-sm p-2 flex justify-center bg-main-red text-white">
          {t("websiteIsUnderConstruction")}
        </div>
        <div
          className={`absolute w-full z-50 left-0 ${navCollapsed
            ? "bg-main-background border-b border-2 border-gray-400 rounded-b-3xl"
            : "bg-transparent"
            } lg:bg-[#1d396b] transition-all duration-300`}
        >
          <Container>
            <Nav
              fluid
              rounded
              className="justify-between bg-opacity-0 lg:bg-transparent"
            >
              <Link to="/" onClick={() => setNavCollapsed(false)}>
                <img
                  src="/images/logos/ell-logo-white.png"
                  className="mr-3 h-16 md:h-32"
                  alt="ell logo"
                />
              </Link>

              <div className="flex items-center justify-end">
                <Nav.Toggle
                  className="bg-white"
                  onClick={() => setNavCollapsed(!navCollapsed)}
                />
              </div>
              <div
                className={`lg:hidden grid grid-cols-${!navCollapsed ? "2" : "3"
                  } lg:grid-cols-2 w-full`}
              >
                <Link to="/" onClick={() => setNavCollapsed(false)}>
                  <img
                    src="/images/logos/ell-logo-white.png"
                    className="mr-3 h-16 md:h-24"
                    alt="ell logo"
                  />
                </Link>
                <div
                  className={`${!navCollapsed && "hidden"
                    } flex flex-col justify-center items-center`}
                >
                  <LanguageSelector />
                </div>
                <div className="flex items-center justify-end">
                  <Nav.Toggle
                    className="bg-white"
                    onClick={() => setNavCollapsed(!navCollapsed)}
                  />
                </div>
              </div>
              <Nav.Collapse
                className={`justify-center items-center bg-darkBackground lg:bg-transparent pb-2 
                  ${isEnglish ? "lg:-translate-x-28" : ""} ${navCollapsed
                    ? "block bg-main-background rounded-b-lg "
                    : "hidden"
                  }
            `}
              >
                <MegaMenu>
                  <MegaMenu.Dropdown className="!py-0" toggle={<div className={`text-white ${isEnglish ? "text-[18px]" : "text-[15px]"}`}>{t("services")}</div>}>
                    <Link
                      to="/services"
                      className={`flex justify-center items-center hover:text-red-700 py-2 px-2 bg-white text-main-blue
                    ${isHomepage ? "text-gray-50 font-normal" : "text-gray-50"} 
                    ${isEnglish ? "text-[18px]" : "text-[15px]"}`}
                      onClick={() => setNavCollapsed(false)}
                    >
                      {t("studyAbroad")}
                    </Link>
                    <Link
                      to="/english-center"
                      className={`flex justify-center items-center hover:text-red-700 py-2 px-2 bg-white text-main-blue
                    ${isHomepage ? "text-gray-50 font-normal" : "text-gray-50"} 
                    ${isEnglish ? "text-[18px]" : "text-[15px]"}`}
                      onClick={() => setNavCollapsed(false)}
                    >
                      {t("englishCenter")}
                    </Link>
                  </MegaMenu.Dropdown>
                </MegaMenu>
                <Link
                  to="/universities"
                  className={`flex justify-center items-center hover:text-red-700 py-1 
                    ${isHomepage ? "text-gray-50 font-normal" : "text-gray-50"} 
                    ${isEnglish ? "text-[18px]" : "text-[15px]"}`}
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("partners")}
                </Link>
                <Link
                  to="/students"
                  className={`flex justify-center items-center hover:text-red-700 py-1 
                    ${isHomepage ? "text-gray-50 font-normal" : "text-gray-50"} 
                    ${isEnglish ? "text-[18px]" : "text-[15px]"}`}
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("students")}
                </Link>
                {/* <Link
                  to="/faq"
                  className={`flex justify-center items-center hover:text-red-700 py-1 
                    ${isHomepage ? "text-gray-50 font-normal" : "text-gray-50"} 
                    ${isEnglish ? "text-[18px]" : "text-[15px]"}`}
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("faq")}
                </Link> */}
                <Link
                  to="/about"
                  className={`flex justify-center items-center hover:text-red-700 py-1 
                    ${isHomepage ? "text-gray-50 font-normal" : "text-gray-50"} 
                    ${isEnglish ? "text-[18px]" : "text-[15px]"}`}
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("about")}
                </Link>
                <Link
                  to="/fares"
                  className={`flex justify-center items-center hover:text-red-700 py-1 
                    ${isHomepage ? "text-gray-50 font-normal" : "text-gray-50"} 
                    ${isEnglish ? "text-[18px]" : "text-[15px]"}`}
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("fares")}
                </Link>
                <Link
                  to="/blog"
                  className={`flex justify-center items-center hover:text-red-700 py-1 
                    ${isHomepage ? "text-gray-50 font-normal" : "text-gray-50"} 
                    ${isEnglish ? "text-[18px]" : "text-[15px]"}`}
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("blog")}
                </Link>
                {/* <Link
                  to="/contact"
                  className={`flex justify-center items-center hover:text-red-700 py-1 
                    ${isHomepage ? "text-gray-50 font-normal" : "text-gray-50"} 
                    ${isEnglish ? "text-[18px]" : "text-[15px]"}`}
                  onClick={() => setNavCollapsed(false)}
                >
                  {t("contact")}
                </Link> */}
                <LanguageSelector />
                <Link to="/contact">
                  <Button color="main-red">
                    {t("contactUs")}
                  </Button>
                </Link>
              </Nav.Collapse>
            </Nav>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Navbar;
