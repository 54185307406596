import React, { useEffect, useState } from "react";
import HeaderSpace from "../../../components/Shared/HeaderSpace/HeaderSpace";
import Sidebar from "../../../components/Shared/Sidebar/Sidebar";
import { Button, Table } from "flowbite-react";

import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase/firebaseConfig";
import { Student } from "../../../interfaces/Student";
import AdminStudentTableRow from "./Table/AdminStudentTableRow";
import AddStudentModal from "./Modals/AddStudentModal";

function AdminStudents() {
  const [students, setStudents] = useState<Student[]>([]);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "students"));
      const items = querySnapshot.docs.map((doc) => doc.data());
      setStudents(items as Student[]);
    };

    fetchData();
  }, []);

  console.log(students);

  return (
    <>
      <HeaderSpace className="bg-white h-20 lg:h-36" />
      <div className="flex flex-col lg:flex-row">
        <Sidebar />
        <div className="flex-1 py-12">
          <div className="w-10/12 mx-auto flex flex-col gap-8">
          <div className="flex flex-col lg:flex-row justify-between gap-4">
          <h2 className="text-main-red font-bold text-3xl text-center lg:text-left lg:text-5xl">
                სტუდენტები
              </h2>
              <Button color={"main-red"} className="bg-main-red flex items-center" onClick={() => setOpenModal(!openModal)}>
                დამატება +
              </Button>
            </div>
            <div className="overflow-x-auto">
              <Table striped>
                <Table.Head>
                  <Table.HeadCell>სურათი</Table.HeadCell>
                  <Table.HeadCell>სახელი და გვარი</Table.HeadCell>
                  <Table.HeadCell>გამოცდილება</Table.HeadCell>
                  <Table.HeadCell>აღწერა</Table.HeadCell>
                  <Table.HeadCell>
                    <span className="sr-only">Edit</span>
                  </Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {students &&
                    students.map((student, index) => (
                      <>
                        <AdminStudentTableRow
                          id={student.id}
                          name={student.name}
                          imageUrl={student.imageUrl}
                          jobs={student.jobs}
                          key={student.id}
                          descriptionHTML={student.descriptionHTML}
                          descriptionHTMLEn={student.descriptionHTMLEn}
                          nameEn={student.nameEn}
                        />
                      </>
                    ))}
                </Table.Body>
              </Table>
            </div>
          </div>
        </div>
      </div>
      <AddStudentModal
      openModal={openModal}
      setOpenModal={setOpenModal}
      />
    </>
  );
}

export default AdminStudents;
